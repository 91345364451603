import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

const RouteLayout = ({
  isAuthenticated, 
  authLayout: AuthLayout, 
  guestLayout: GuestLayout, 
  component: Component, 
  ...rest 
}) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if(isAuthenticated) {
          return (
            <AuthLayout>
              <Component {...matchProps} />
            </AuthLayout>
          )
        }

        return (
          <GuestLayout>
            <Component {...matchProps} />
          </GuestLayout>
        )
      }}
    />
  )
}

RouteLayout.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.any.isRequired,
  authLayout: PropTypes.any.isRequired,
  guestLayout: PropTypes.any.isRequired,
  path: PropTypes.string
}

export default RouteLayout
