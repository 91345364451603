import { combineReducers } from 'redux'

import listProduct from './listProduct'
import searchProduct from './searchProduct'
import detailProduct from './detailProduct'

export default combineReducers({
  listProduct: listProduct,
  searchProduct: searchProduct,
  detailProduct: detailProduct
})
