import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

const MinimalLayout = ({
  component: Component,
  minimalLayout: MinimalLayout,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        return (
          <MinimalLayout>
            <Component {...matchProps} />
          </MinimalLayout>
        )
      }}
    />
  )
}

MinimalLayout.propTypes = {
  component: PropTypes.any.isRequired,
  minimalLayout: PropTypes.any.isRequired,
  path: PropTypes.string
}

export default MinimalLayout
