
function requestAPI() {
  return fetch(`${process.env.REACT_APP_BASE_URL}producer`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json())
}

export default function actionListProducer() {
  return async (dispatch) => {
    dispatch({
      type: 'LIST_PRODUCER_BEGIN'
    })

    try {
      const result = await requestAPI()
      if(!result.status) {
        handleErrors(dispatch, result)
        return
      }
      
      await result.data.unshift({ id: '', name: 'Semua Produsen' })
      dispatch({
        type: 'LIST_PRODUCER_SUCCESS',
        payload: result.data
      })
    } catch (err) {
      handleErrors(dispatch, err)
    }
  }
}

function handleErrors(dispatch, error) {
  dispatch({        
    type: 'LIST_PRODUCER_FAILURE',        
    error: error    
  })
}