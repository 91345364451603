
function requestAPI(data) {
  return fetch(`${process.env.REACT_APP_AGENT_URL}register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then((res) => res.json())
}

export default function actionRegister(data) {
  return async (dispatch) => { 
    dispatch({
      type: 'AUTH_REGISTER_BEGIN'
    })

    try {
      const result = await requestAPI(data)
      if(!result.status) {
        handleErrors(dispatch, result)
        return
      }
      
      dispatch({        
        type: 'AUTH_REGISTER_SUCCESS',
        success: result
      })
    } catch(err) {
      handleErrors(dispatch, err)
    }
  }
}

function handleErrors(dispatch, error) {
  dispatch({        
    type: 'AUTH_REGISTER_FAILURE',        
    error: error    
  })
}