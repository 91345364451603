import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { actionAlert } from '../../store/actions/util'

const Snackbar = ({ active, severity, message, dispatchAlert }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if(active) {
        dispatchAlert(false, severity, message)
      }
    }, 6000)
    return () => clearTimeout(timer)
  }, [active, dispatchAlert])

  const handleCloseAlert = () => {
    dispatchAlert(false, severity, message)
  }

  if(!active) return null

  return (
    <div 
      id="snackbar" 
      className="content"
      onClick={handleCloseAlert}
    >
      <div className={`content ${severity}`}>
        {
          (severity === 'success')
          ? <FontAwesomeIcon icon={faCheckSquare} />
          : <FontAwesomeIcon icon={faExclamationTriangle} />
        }

        {' '} {message}
      </div>
    </div>
  )
}

Snackbar.propTypes = {
  active: PropTypes.bool.isRequired,
  severity: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchAlert: (status, severity, message) => dispatch(actionAlert(status, severity, message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar)