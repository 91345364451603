import React, { 
  useState, 
  useEffect 
} from 'react'
import validate from 'validate.js'
import { withRouter } from 'react-router-dom'

import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  actionUpdateCart,
  actionRemoveCart,
  actionRemoveAllCart,
  actionGetVoucher,
} from '../../store/actions/cart'
import { actionSaveTransaction } from '../../store/actions/transaction'
import { actionAlert } from '../../store/actions/util'

import { formatPrice } from '../../helpers'

const schema = {
  sDetail: {
    presence: { 
      allowEmpty: false, 
      message: 'Pengirim harus diisi' 
    }
  },
  rDetail: {
    presence: { 
      allowEmpty: false, 
      message: 'Tujuan pengiriman harus diisi' 
    }
  }
}

const Cart = ({
  history,
  authenticate,
  contact,
  cart,
  voucher,
  transaction,
  dispatchAlert,
  dispatchUpdateCart,
  dispatchRemoveCart,
  dispatchRemoveAllCart,
  dispatchSaveTransaction,
  dispatchGetVoucher
}) => {
  const [total, setTotal] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [message, setMessage] = useState('')
  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('')
  const [onState, setOnState] = useState(false)

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      sDetail: (authenticate.account)
        ? `${authenticate.account.name}\nTelp ${authenticate.account.phone}`
        : ''
    },
    touched: {},
    errors: {}
  })

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }))
  }, [formState.values])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const hasError = field => formState.errors[field] ? true : false

  useEffect(() => {
    setTotal(cart.payload.reduce((current, item) => current + (item.price * item.quantity), 0))
  }, [cart.payload])

  useEffect(() => {
    if (voucher.payload) {
      setDiscount(parseFloat(voucher.payload?.value || 0))
      return
    }

    setDiscount(0)
  }, [voucher.payload])

  useEffect(() => {
    if (voucher.payload && total < parseFloat(voucher.payload?.min_purchase)) {
      dispatchGetVoucher(code, total)
    }
  }, [total, voucher.payload])

  const handlePageHome = () => {
    history.push('/')
  }

  const handleChangeCode = (event) => {
    setCode(event.target.value)
  }

  const handleSearchVoucher = () => {
    if(!voucher.loading) {
      dispatchGetVoucher(code, total)
    }
  }

  const handleUpdateQuantity = (product, price, quantity) => {
    if(quantity > 0) {
      dispatchUpdateCart(product, price, quantity)
    }
  }

  const handleRemoveCart = (productId) => {
    dispatchRemoveCart(productId)
  }

  useEffect(() => {
    if(transaction.payload && onState) {
      setOnState(false)
      dispatchRemoveAllCart()
      const text = `Kode ${transaction.payload} %0A%0A${message}`
      window.open(`${process.env.REACT_APP_WHATSAPP_URL}${phone}?text=${text}`, '_blank')
    }
  }, [transaction.payload, dispatchRemoveAllCart, onState])

  const handleCheckout = () => {
    if(!transaction.loading && !voucher.loading) {
      if(formState.isValid) {
        const data = {
          total: 0,
          user_id: (authenticate.account)? authenticate.account.id : null,
          orders: [],
          sender: {
            detail: formState.values.sDetail
          },
          recipient: {
            detail: formState.values.rDetail
          },
          voucher_id: (voucher.payload) ? voucher.payload?.id : null,
        }

        let text = ''
        for(const item of cart.payload) {
          data.total += (Number(item.price) * Number(item.quantity))
          data.orders.push({
            quantity: Number(item.quantity),
            price: Number(item.price),
            product_id: item.product.id
          })
          text += `- ${item.product.name} @${formatPrice(item.price, 'Rp.')} x ${item.quantity}%0A`
        }

        if (authenticate.account) {
          text += `%0ATotal Belanja ${formatPrice(total, 'Rp.')}`
          text += `%0APotongan Harga ${formatPrice(discount, '- Rp.')}`
        }
        text += `%0ATotal Bayar ${formatPrice((total - discount), 'Rp.')}%0A%0A`

        text += `${formState.values.rDetail.replace(/\n/gm,'%0A')}%0A%0A`
        text += `Pengirim%0A${formState.values.sDetail.replace(/\n/gm,'%0A')}`
        setMessage(text)
        setPhone(contact.payload.phone)
        setOnState(true)
        dispatchSaveTransaction(data)
        setDiscount(0)
        setCode('')
        return
      }

      dispatchAlert(
        true, 
        'error', 
        hasError('rDetail')
        ? formState.errors.rDetail[0] 
        : formState.errors.sDetail[0]
      )
    }
  }

  return (
    <>
      <div className="breadcrumb-halaman">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a 
                onClick={handlePageHome} 
                className="clickable" 
              >
                Beranda
              </a>
            </li>
            <li className="breadcrumb-item active">
              Keranjang belanja
            </li>
          </ol>
        </nav>
      </div>
      <div className="totalbayar-mobile">
        <div className="voucher-mobile">
          <label htmlFor="voucher">Kode Voucher</label>
          <div className="form-voucher">
            <input 
              type="text"
              name="voucher"
              id="voucher"
              value={code}
              onChange={handleChangeCode}
            />

            <div
              className="btn-voucher"
              onClick={handleSearchVoucher}
            >
              Gunakan
            </div>
          </div>
        </div>
        <div className="detail">
          <div className="isi-total">
            <p className="totalharga">Total Bayar</p>
            <p className="total">{formatPrice((total - discount), 'Rp.')}</p>
            {
              (discount > 0) && (
                <p className="totalpotongan">{formatPrice(total, 'Rp.')}</p>
              )
            }
          </div>
          {
            (cart.payload.length > 0)
            ? <div 
                onClick={handleCheckout} 
                className="btn-bayar"
              >
                <a className="btn-bayar">
                  {
                    (transaction.loading || voucher.loading)
                    ? 'Menunggu..'
                    : `Beli produk (${cart.payload.length})`
                  }
                </a>
              </div>
            : null
          }
        </div>
      </div>
      <div className="container">
        <div className="cover-keranjang-all">
          <div className="cover-isi">
            <div className="cover-card-produk">
              <h2 className="judul">
                Keranjang belanja
              </h2>
              {
                (cart.payload.length > 0)
                ? <>
                    {
                      cart.payload.map((item, index) => {
                        return (
                          <div 
                            key={index} 
                            className="card-produk"
                          >
                            <img 
                              src={process.env.REACT_APP_IMAGE_URL + item.product.image}
                              alt={item.product.name} 
                            />
                            <div className="isi-konten">
                              <p className="judul">
                                {item.product.name}
                              </p>
                              <p className="harga">
                                {formatPrice(item.price, 'Rp.')}
                              </p>
                            </div>
                            <div className="btn-card-prod">
                              <div className="nice-number">
                                <button 
                                  type="button"
                                  onClick={() => 
                                    handleUpdateQuantity(
                                      item.product, 
                                      item.price, 
                                      (item.quantity-1)
                                    )
                                  }
                                >
                                  -
                                </button>
                                <input 
                                  type="number" 
                                  value={item.quantity} 
                                  readOnly 
                                />
                                <button 
                                  type="button"
                                  onClick={() => 
                                    handleUpdateQuantity(
                                      item.product, 
                                      item.price, 
                                      (item.quantity+1)
                                    )
                                  }
                                >
                                  +
                                </button>
                              </div>
                              <a 
                                className="pointer" 
                                onClick={() => 
                                  handleRemoveCart(item.product.id)
                                }
                              >
                                Hapus
                              </a>
                            </div>
                          </div>
                        )
                      })
                    }

                    <h2 className="judul">
                      Informasi Pengiriman
                    </h2>
                    <div className="rDetail">
                      <label htmlFor="rDetail">
                        Tujuan pengiriman <br/>
                        (Silahkan masukkan nama ,alamat, telp sesuai contoh)
                      </label>
                      <textarea 
                        id="rDetail"
                        type="text" 
                        rows="5"
                        name="rDetail"
                        onChange={handleChange}
                        value={formState.values.rDetail || ''}
                        placeholder="Kepada Ahmad &#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;Jalan uji coba no.1 &#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;Jakarta &#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;Telp 0811223344"
                      ></textarea>
                    </div>
                    <div className="sDetail">
                      <label htmlFor="sDetail">
                        Pengirim <br/>
                        (Silahkan masukkan info Toko dan Telp Dropshipper)
                      </label>
                      <textarea 
                        id="sDetail"
                        type="text" 
                        rows="3"
                        name="sDetail"
                        onChange={handleChange}
                        value={formState.values.sDetail || ''}
                        placeholder="Toko Herbal A &#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;&#x09;Telp 0811223344"
                      ></textarea>
                    </div>
                  </>
                : <div className="btn-bayar">
                    <a 
                      onClick={handlePageHome} 
                      className="btn-bayar"
                    >
                      Belanja Sekarang!
                    </a>
                  </div>
              }
            </div>
            <div className="totalbayar">
              <h2 className="ringkasan">
                Ringkasan belanja
              </h2>
              {
                authenticate.account && (
                  <div className="voucher">
                    <label htmlFor="voucher">Kode Voucher</label>
                    <div className="form-voucher">
                      <input 
                        type="text"
                        name="voucher"
                        id="voucher"
                        value={code}
                        onChange={handleChangeCode}
                      />

                      <div
                        className="btn-voucher"
                        onClick={handleSearchVoucher}
                      >
                        Gunakan
                      </div>
                    </div>
                  </div>
                )
              }

              <div className="isi-total">
                {
                  authenticate.account && (
                    <>
                      <p className="totalharga">Total Belanja</p>
                      <p className="total">{formatPrice(total, 'Rp.')}</p>
                      <p className="totalharga">Potongan Harga</p>
                      <p className="totalpotongan">{formatPrice(discount, '- Rp.')}</p>
                    </>
                  )
                }
                <p className="totalharga">Total Bayar</p>
                <p className="total">{formatPrice((total - discount), 'Rp.')}</p>
              </div>

              {
                (cart.payload.length > 0 || voucher.loading)
                ? <div 
                    onClick={handleCheckout} 
                    className="btn-bayar"
                  >
                    <a className="btn-bayar">
                      Beli produk ({cart.payload.length})
                    </a>
                  </div>
                : null
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticate: {
      account: state.auth.authenticate.account
    },
    voucher: {
      loading: state.cart.getVoucher.loading,
      payload: state.cart.getVoucher.payload
    },
    contact: {
      payload: state.util.contact.payload
    },
    cart: {
      payload: state.cart.getCart.payload
    },
    transaction: {
      loading: state.transaction.saveTransaction.loading,
      payload: state.transaction.saveTransaction.payload
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchAlert: (status, severity, message) => dispatch(actionAlert(status, severity, message)),
    dispatchUpdateCart: (product, price, quantity) => dispatch(actionUpdateCart(product, price, quantity)),
    dispatchRemoveCart: (productId) => dispatch(actionRemoveCart(productId)),
    dispatchRemoveAllCart: () => dispatch(actionRemoveAllCart()),
    dispatchSaveTransaction: (data) => dispatch(actionSaveTransaction(data)),
    dispatchGetVoucher: (name, total) => dispatch(actionGetVoucher(name, total)),
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Cart)