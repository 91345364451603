import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { withRouter, useParams } from 'react-router-dom'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionDetailCategory } from '../../store/actions/category'
import { actionListProducer } from '../../store/actions/producer'
import { actionListProduct } from '../../store/actions/product'

import { Loader } from '../../components'
import { formatPrice, formatDiscount, formatPrecentage } from '../../helpers'

const Category = ({ 
  history, 
  authenticate,
  category,
  producer, 
  product,  
  dispatchDetailCategory, 
  dispatchListProducer,
  dispatchListProduct
}) => {
  const { id } = useParams()
  const limit = 12
  const [categoryName, setCategoryName] = useState('')
  const [categoryImage, setCategoryImage] = useState('')
  const [onState, setOnState] = useState(true)
  const [updateState, setUpdateState] = useState(false)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [producerId, setProducerId] = useState('')
  const [producerName, setProducerName] = useState('Semua Produsen')
  const [products, setProducts] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    dispatchDetailCategory(id)
    dispatchListProducer()
  }, [id, dispatchDetailCategory, dispatchListProducer])

  useEffect(() => {
    if(onState) {
      dispatchListProduct(page, limit, search, producerId, id)
      setUpdateState(true)
    }
  }, [dispatchListProduct, onState, page, limit, producerId, search, id])

  useEffect(() => {
    if(!product.loading && updateState) {
      setOnState(false)
      setUpdateState(false)
      setProducts(products.concat(product.payload))
      setPage(product.page)
      setPages(product.pages)
    }
  }, [product.payload, product.loading, product.page, product.pages, updateState])

  useEffect(() => {
    if(category.payload) {
      setCategoryName(category.payload.name)
      setCategoryImage(category.payload.image)
    }
  }, [category.payload])

  const handlePageHome = () => {
    history.push('/')
  }

  const handleSelectProducer = (item) => {
    setProducts([])
    setProducerId(item.id)
    setProducerName(item.name)
    setPage(1)
    setOnState(true)
  }

  const handleChangeSearch = (event) => {
    setSearch(event.target.value)
  }

  const handleLoadMore = () => {
    setPage(page+1)
    setOnState(true)
  }

  const handleSearchProduct = () => {
    setProducts([])
    setPage(1)
    setOnState(true)
  }

  const handlePageProduct = (productId) => {
    history.push(`/produk/${productId}`)
  }

  if(!category.payload) return <Loader />

  return (
    <>
      <div className="breadcrumb-halaman">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a 
                onClick={handlePageHome} 
                className="clickable" 
              >Beranda</a>
            </li>
            <li className="breadcrumb-item active">Kategori</li>
            <li className="breadcrumb-item active">{categoryName}</li>
          </ol>
        </nav>
      </div>

      <div className="container">
        <div className="cover-katalogheader">
          <div className="gambar">
            <img 
              src={process.env.REACT_APP_IMAGE_URL + categoryImage} 
              alt={categoryName}
              title="aksen-kat" 
            />
          </div>
          <div className="judul-cat">
            <h3>{categoryName}</h3>
          </div>
          <div className="cover-filter">
            <Dropdown className="dropdowncat">
              <Dropdown.Toggle className="btn-secondary btn-cat">
                <span className="name">{producerName}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu-right">
                {
                  producer.payload.map((item, index) => {
                    return (
                      <Dropdown.Item 
                        key={index}
                        eventKey={item.id}
                        onClick={() => handleSelectProducer(item)}
                      >
                        {item.name}
                      </Dropdown.Item>
                    )
                  })
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="cover-cari">
            <input 
              type="text" 
              onChange={handleChangeSearch} 
              value={search}
            />
            <button className="btn-cari" onClick={handleSearchProduct}>Cari</button>
          </div>
        </div>

        <div className="cover-katalog">
          {
            products.map((item, index) => {
              return (
                <div 
                  key={index} 
                  className="card-catalog"
                  onClick={() => handlePageProduct(item.id)}
                >
                  <img 
                    src={process.env.REACT_APP_IMAGE_URL + item.image} 
                    alt={item.name} 
                    title="foto-catalog" 
                  />
                  <p className="judul-produk">{item.name}</p>
                  <p className="produsen-produk">{item.producer.name}</p>
                  <p className="diskon">{formatPrecentage(authenticate.account, item.discount)}%</p>
                  <p className="harga-diskon">{formatPrice(item.price, 'Rp.')}</p>
                  <p className="harga-produk">
                    {
                      formatDiscount(
                        item.price, 
                        formatPrecentage(authenticate.account, item.discount), 
                        'Rp.'
                      )
                    }
                  </p>
                </div>
              )
            })
          }
        </div>

        <div className="cover-more">
          {
            (page < pages)
            ? <a onClick={handleLoadMore} className="btn-more">Muat Lebih Banyak</a>
            : <div className="text-more">Sudah Ditampilkan Semua</div>
          }
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticate: {
      account: state.auth.authenticate.account
    },
    category: {
      loading: state.category.detailCategory.loading,
      payload: state.category.detailCategory.payload
    },
    producer: {
      loading: state.producer.listProducer.loading,
      payload: state.producer.listProducer.payload
    },
    product: {
      loading: state.product.listProduct.loading,
      page: state.product.listProduct.page,
      pages: state.product.listProduct.pages,
      payload: state.product.listProduct.payload
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchDetailCategory: (categoryId) => dispatch(actionDetailCategory(categoryId)),
    dispatchListProducer: () => dispatch(actionListProducer()),
    dispatchListProduct: (page, limit, name, producerId, categoryId) => dispatch(actionListProduct(page, limit, name, producerId, categoryId))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Category)