const initialState = { 
  loading: false,
  payload: null,
  error: null
}

function saveTransactionReducer(state = initialState, action) {
  switch (action.type) {
    case 'SAVE_TRANSACTION_BEGIN':
      return {
        ...state,
        loading: true,
        payload: null,
        error: null
      }

    case 'SAVE_TRANSACTION_SUCCESS':
      return {
        ...state,
        loading: false,
        payload: action.payload
      }

    case 'SAVE_TRANSACTION_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default: return state
  }
}

export default saveTransactionReducer