import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import validate from 'validate.js'

import { connect } from 'react-redux'
import { actionAlert } from '../../../../store/actions/util'

const schema = {
  old_password: {
    presence: { 
      allowEmpty: false, 
      message: 'Password lama harus di isi' 
    }
  },
  new_password: {
    presence: { 
      allowEmpty: false, 
      message: 'Password baru harus di isi' 
    }
  },
  confirm_password: {
    presence: { 
      allowEmpty: false, 
      message: 'Password konfirmasi  harus diisi' 
    },
    equality: {
      attribute: "new_password",
      message: "Password konfirmasi harus sama dengan password baru",
    }
  }
}

const Form = ({ 
  loading,
  onChangePassword, 
  dispatchAlert 
}) => {
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  })

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }))
  }, [formState.values])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const hasError = field => formState.errors[field] ? true : false

  const handleChangePassword = (event) => {
    event.preventDefault()

    if(formState.isValid) {
      // console.log(formState.isValid+":")
      onChangePassword(formState.values)
      return
    }
    dispatchAlert(
      true, 
      'error', 
      hasError('old_password')
      ? formState.errors.old_password[0]
      : hasError('new_password')
      ? formState.errors.new_password[0] 
      : formState.errors.confirm_password[0] 
    )
  }


  return (
    <div className="cover-pagedaftarform">
      <div className="cover-headerdaftaragen">
        <div className="judul">
          <h2>Ganti Password</h2>
          <p>Ganti password dengan password yang baru</p>
        </div>
      </div>
      <div className="formdaftar">
        <div className="card-daftar">
          <div className="close">
            <span></span>
          </div>
          <div className="old_password">
            <label htmlFor="old_password">Password Lama</label>
            <input 
              type="password" 
              name="old_password" 
              id="old_password" 
              onChange={handleChange}
              value={formState.values.old_password || ''}
            />
          </div>
          <div className="new_password">
            <label htmlFor="new_password">Password Baru</label>
            <input 
              type="password" 
              name="new_password" 
              id="new_password" 
              onChange={handleChange}
              value={formState.values.new_password || ''}
            />
          </div>
          <div className="confirm_password">
            <label htmlFor="confirm_password">Password Konfirmasi </label>
            <input 
              type="password" 
              name="confirm_password" 
              id="confirm_password" 
              onChange={handleChange}
              value={formState.values.confirm_password || ''}
            />
          </div>
          <div className="cover-btndaftar">
            <a onClick={handleChangePassword} className="btn-daftar">{'Ganti Password'}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

Form.propTypes = {
  loading: PropTypes.bool.isRequired,
  onChangePassword: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchAlert: (status, severity, message) => dispatch(actionAlert(status, severity, message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form)