const initialState = { 
  loading: false,
  payload: [],
  error: null
}

function getCartReducer(state = initialState, action) {
  switch (action.type) {
    case 'ADD_CART_BEGIN':
    case 'GET_CART_BEGIN':
    case 'UPDATE_CART_BEGIN':
    case 'REMOVE_CART_BEGIN':
    case 'REMOVE_ALL_CART_BEGIN':
      return {
        ...state,
        loading: true,
        error: null
      }

    case 'ADD_CART_SUCCESS':
    case 'GET_CART_SUCCESS':
    case 'UPDATE_CART_SUCCESS':
    case 'REMOVE_CART_SUCCESS':
    case 'REMOVE_ALL_CART_SUCCESS':
      return {
        ...state,
        loading: false,
        payload: action.payload
      }

    case 'ADD_CART_FAILURE':
    case 'GET_CART_FAILURE':
    case 'UPDATE_CART_FAILURE':
    case 'REMOVE_CART_FAILURE':
    case 'REMOVE_ALL_CART_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default: return state
  }
}

export default getCartReducer