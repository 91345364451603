import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import WebFont from 'webfontloader'
import App from './App'
import './index.css'

WebFont.load({
  google: {
    families: ['Montserrat:300,400,500,600', 'sans-serif']
  }
})

ReactDOM.render(<App />, document.getElementById('root'))
