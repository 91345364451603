
export default function actionUpdateCart(product, price, quantity) {
  return async(dispatch) => {
    try {
      dispatch({
        type: 'UPDATE_CART_BEGIN'
      })
      
      const result = await localStorage.getItem('nabawi-cart')
      const payload = (result)? JSON.parse(result) : []
      const indexExists = await payload.findIndex((item) => item.product.id === product.id)

      if(indexExists > -1) {
        payload[indexExists].quantity = quantity
        payload[indexExists].price = price
      } else {
        payload.push({ product: product, price: price, quantity: quantity })
      }
      
      localStorage.setItem('nabawi-cart', JSON.stringify(payload))

      dispatch({
        type: 'UPDATE_CART_SUCCESS',
        payload: payload
      })
    } catch (err) {
      dispatch({
        type: 'UPDATE_CART_FAILURE'
      })
    }
  }
}