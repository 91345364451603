const initialState = {
  loading: true,
  token: '',
  account: null,
  status: false
}

function changePasswordReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_CHANGE_PASSWORD_BEGIN':
      return {
        ...state,
        loading: true,
        success: null,
        error: null
      }

    case 'GET_CHANGE_PASSWORD_SUCCESS':
      return {
        ...state,
        success: action.success,
        loading: false
      }
    
    case 'GET_CHANGE_PASSWORD_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default: 
      return state
  }
}

export default changePasswordReducer