import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faCartPlus, faUser } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { withRouter } from 'react-router-dom'
import { Popover, OverlayTrigger } from 'react-bootstrap'

import { Snackbar } from '../../components'
import PropTypes from 'prop-types'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionAlert } from '../../store/actions/util'
import { actionSearchProduct } from '../../store/actions/product'
import { actionLogout } from '../../store/actions/auth'

const Auth = ({ 
  history, 
  children,
  contact, 
  modal, 
  product,
  cart,
  alert, 
  dispatchAlert, 
  dispatchLogout,
  dispatchSearchProduct
}) => {
  const [showCs, setShowCs] = useState(false)

  const handleCloseAlert = () => {
    dispatchAlert(false, alert.severity, alert.message)
  }

  const handlePageChangePassword= () => {
    history.push('/ganti-password')
  }

  const handleLogout = () => {
    dispatchLogout()
  }

  const handlePageHome = () => {
    history.push('/')
  }

  const handlePageCart = () => {
    history.push('/keranjang')
  }

  const handleChangeQuery = (event) => {
    event.persist()

    dispatchSearchProduct(false, event.target.value)
  }

  const handleSearchProduct = () => {
    dispatchSearchProduct(true, product.query)
    history.push('/')
  }

  const handleActiveClassName = (current, active) =>  {
    if(active) return current + ' active'
    return current
  }

  const handleToogleCs = (active) => {
    setShowCs(active)
  }

  const handleTextCs = () => {
    window.open(`${process.env.REACT_APP_WHATSAPP_URL}${contact.payload.phone}?text=Halo Admin Dropshipping %0A%0A`, '_blank')
  }

  const PopoverMenu = (
    <Popover>
      <Popover.Content onClick={handlePageChangePassword}>
        Ganti Password
      </Popover.Content>
      <Popover.Content onClick={handleLogout}>
        Keluar
      </Popover.Content>
    </Popover>
  )

  return (
    <div>
      <Snackbar 
        active={alert.status} 
        severity={alert.severity} 
        message={alert.message} 
        onClose={handleCloseAlert} 
      />

      <div 
        className="transparan" 
        style={{ display: (modal.active) ? 'block' : 'none' }}
      ></div>

      <div 
        className={
          handleActiveClassName(
            'hubungikami', 
            showCs
          )
        }
      >
        <div 
          onClick={() => handleToogleCs(false)}
          className="judul"
        >
          <img src={process.env.REACT_APP_IMAGE_URL + 'img/wa.png'} alt="" />
          <p>Hubungi kami</p>
        </div>
        <div className="konten-wa">
          <a onClick={handleTextCs}>
            <img src={process.env.REACT_APP_IMAGE_URL + 'img/cs.png'} alt="" />
            <p>Admin</p>
          </a>
        </div>
        {
          (!showCs)
          ? <a
              onClick={() => handleToogleCs(true)} 
              className="footer"
            >
              <img src={process.env.REACT_APP_IMAGE_URL + 'img/wa.png'} alt="" />
              <p>Hubungi kami</p>
            </a>
          : null
        }
      </div>

      <div className="container-besar">
        <nav className="navigation">
          <img 
            onClick={handlePageHome} 
            src={process.env.REACT_APP_IMAGE_URL + 'img/logo-dropshipper.png'} 
            className="logo" 
            alt="logo-dropshipper" 
            title="logo-dropshipper"
          />
          <div className="cover-cari">
            <input 
              type="text" 
              placeholder="cari barang" 
              value={product.query} 
              onChange={handleChangeQuery}
              onKeyPress={event => event.key === 'Enter' && handleSearchProduct()}
            />
            <button id="cari" onClick={handleSearchProduct}>Cari</button>
          </div>
          <div onClick={handlePageCart} className="cover-keranjang">
            <img 
              src={process.env.REACT_APP_IMAGE_URL + 'img/keranjang-dropshipper.svg'}
              className="keranjang-belanja" 
              alt="keranjang-belanja" 
              title="keranjang-belanja" 
            />
            {
              (cart.payload.length > 0)
              ? <span className="badges-keranjang">{cart.payload.length}</span>
              : null
            }
          </div>
          <div className="button-action">
            <a onClick={handlePageChangePassword} className="btn-daftar">Ganti Password</a>
            <a onClick={handleLogout} className="btn-masuk" id="btn-masuk">Keluar</a>
          </div>
        </nav>
      </div>
      <div className="covernav-mobile">
        <nav className="nav-mobile">
          <a 
            onClick={handlePageHome} 
            className={
              handleActiveClassName(
                'menu-mobile', 
                (history.location.pathname === '/')
              )
            }
          >
            <FontAwesomeIcon className="fas" icon={faHome} />
            <p>Beranda</p>
          </a>
          <a 
            onClick={handlePageCart} 
            className={
              handleActiveClassName(
                'menu-mobile', 
                (history.location.pathname === '/keranjang')
              )
            }
          >
            <FontAwesomeIcon className="fas" icon={faCartPlus} />
            <p>Keranjang</p>
            {
              (cart.payload.length > 0)
              ? <div className="notif">{cart.payload.length}</div>
              : null
            }
          </a>
          <a 
            onClick={() => handleToogleCs(!showCs)}
            className="menu-mobile active"
          >
            <FontAwesomeIcon className="fas" icon={faWhatsapp} />
            <p>Chat</p>
          </a>
          <OverlayTrigger 
            trigger="click" 
            placement="top" 
            overlay={PopoverMenu}
          >
            <a className="menu-mobile">
              <FontAwesomeIcon className="fas" icon={faUser} />
              <p>Profil</p>
            </a>
          </OverlayTrigger>
        </nav>
      </div>
      <main>
        {children}
      </main>
    </div>
  )
}

Auth.propTypes = {
  children: PropTypes.node
}

const mapStateToProps = (state) => {
  return {
    contact: {
      payload: state.util.contact.payload
    },
    cart: {
      payload: state.cart.getCart.payload
    },
    product: {
      status: state.product.searchProduct.status,
      query: state.product.searchProduct.query
    },
    modal: {
      active: state.util.modal.active
    },
    alert: {
      status: state.util.alert.status,
      severity: state.util.alert.severity,
      message: state.util.alert.message
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchAlert: (status, severity, message) => dispatch(actionAlert(status, severity, message)),
    dispatchLogout: () => dispatch(actionLogout()),
    dispatchSearchProduct: (status, query) => dispatch(actionSearchProduct(status, query))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Auth)
