
function requestAPI(categoryId) {
  return fetch(`${process.env.REACT_APP_BASE_URL}category/${categoryId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json())
}

export default function actionDetailCategory(categoryId) {
  return async (dispatch) => {
    dispatch({
      type: 'DETAIL_CATEGORY_BEGIN'
    })

    try {
      const result = await requestAPI(categoryId)
      if(!result.status) {
        handleErrors(dispatch, result)
        return
      }

      dispatch({
        type: 'DETAIL_CATEGORY_SUCCESS',
        payload: result.data
      })
    } catch (err) {
      handleErrors(dispatch, err)
    }
  }
}

function handleErrors(dispatch, error) {
  dispatch({        
    type: 'DETAIL_CATEGORY_FAILURE',        
    error: error    
  })
}