
export default function actionAlert(status, severity, message) {
  return (dispatch) => {
    dispatch({        
      type: 'UTIL_ALERT',
      status: status,
      severity: severity,
      message: message
    })
  }
}