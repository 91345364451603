import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

const ModalSuccess = ({ modalShow, onModalHide, onModalClick }) => {
  return (
    <Modal
      show={modalShow}
      onHide={onModalHide}
      centered
    >
      <Modal.Body className="success-register">
        <h2>Pendaftaran Agen Berhasil</h2>
        <p>Selamat, akun Anda sudah aktif. Silahkan masuk agen untuk untuk menikmati diskon dari kami.</p>
        <div className="order-now">
          <a onClick={onModalClick} className="order-now">Belanja Sekarang!</a>
        </div>
      </Modal.Body>
    </Modal>
  )
}

ModalSuccess.propTypes = {
  modalShow: PropTypes.bool.isRequired,
  onModalHide: PropTypes.func.isRequired,
  onModalClick: PropTypes.func.isRequired
}

export default ModalSuccess