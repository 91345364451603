import { combineReducers } from 'redux'

import authenticate from './authenticate'
import login from './login'
import register from './register'
import changePassword from './changePassword'

export default combineReducers({
  authenticate: authenticate,
  login: login,
  register: register,
  changePassword: changePassword
})
