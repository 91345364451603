const initialState = {
  status: false,
  query: ''
}

function searchProductReducer(state = initialState, action) {
  switch (action.type) {
    case 'SEARCH_PRODUCT':
      return {
        ...state,
        status: action.status,
        query: action.query
      }

    default: 
      return state
  }
}

export default searchProductReducer