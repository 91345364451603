import React from 'react'

import { Snackbar } from '../../components'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { actionAlert } from '../../store/actions/util'

const Minimal = ({ 
  children, 
  modal,
  alert, 
  dispatchAlert
}) => {

  const handleCloseAlert = () => {
    dispatchAlert(false, alert.severity, alert.message)
  }

  return (
    <div>
      <Snackbar 
        active={alert.status} 
        severity={alert.severity} 
        message={alert.message} 
        onClose={handleCloseAlert} 
      />

      <div 
        className="transparan" 
        style={{ display: (modal.active) ? 'block' : 'none' }}
      ></div>

      <main>
        {children}
      </main>
    </div>
  )
}

Minimal.propTypes = {
  children: PropTypes.node
}

const mapStateToProps = (state) => {
  return {
    modal: {
      active: state.util.modal.active
    },
    alert: {
      status: state.util.alert.status,
      severity: state.util.alert.severity,
      message: state.util.alert.message
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchAlert: (status, severity, message) => dispatch(actionAlert(status, severity, message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Minimal)
