import React from 'react'
import PropTypes from 'prop-types'

const Package = ({ onChoosePackage }) => {
  return (
    <div className="cover-pagedaftar">
      <div className="cover-headerdaftaragen">
        <div className="judul">
          <h2>Paket agen</h2>
          <p>Pilih paket agen yang anda inginkan</p>
        </div>
        <div className="step">
          <p>Step 1</p>
        </div>
      </div>

      <div className="cover-daftaragen">
        <div className="card-daftaragen" id="agn3">
          <p className="judul-agen">Dropshipping Agen</p>
          <p className="diskon">Diskon rata-rata 40%</p>
          <p className="keterangan">Terdaftar Agen Nabawi Herba untuk semua Grade Agen</p>
          <button 
            onClick={() => onChoosePackage('grade_b')} 
            className="btn-pilih" 
            id="agn3"
          >pilih paket</button>
        </div>
        <div className="card-daftaragen" id="agn4">
          <p className="judul-agen">Dropshipping Non Agen</p>
          <p className="diskon">Diskon rata-rata 35%</p>
          <p className="keterangan">Belum Menjadi Agen, hanya Dropshipping</p>
          <button 
            onClick={() => onChoosePackage('grade_c')}
            className="btn-pilih" 
            id="agn4"
          >pilih paket</button>
        </div>
      </div>
    </div>
  )
}

Package.propTypes = {
  onChoosePackage: PropTypes.func.isRequired
}

export default Package