import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { actionGetAuthenticate } from '../../store/actions/auth'
import { actionGetCart } from '../../store/actions/cart'
import { actionContact } from '../../store/actions/util'

import { Loader } from '../../components'

const Splash = ({ 
  authenticate, 
  dispatchGetAuthenticate, 
  dispatchGetCart,
  dispatchContact,
  handleEndedSplash 
}) => {
  const [onState, setOnState] = useState(true)

  useEffect(() => {
    dispatchGetAuthenticate()
    dispatchGetCart()
    dispatchContact()
  }, [dispatchGetAuthenticate, dispatchGetCart, dispatchContact])

  useEffect(() => {
    if(!authenticate.loading && onState) {
      handleEndedSplash()
      setOnState(false)
    }
  }, [authenticate.loading, onState, handleEndedSplash])

  return <Loader />
}

Splash.propTypes = {
  handleEndedSplash: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    authenticate: {
      loading: state.auth.authenticate.loading,
      status: state.auth.authenticate.status
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchGetAuthenticate: () => dispatch(actionGetAuthenticate()),
    dispatchGetCart: () => dispatch(actionGetCart()),
    dispatchContact: () => dispatch(actionContact())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Splash)
