const initialState = { 
  loading: false,
  payload: [],
  error: null
}

function listProducerReducer(state = initialState, action) {
  switch (action.type) {
    case 'LIST_PRODUCER_BEGIN':
      return {
        ...state,
        loading: true,
        error: null
      }

    case 'LIST_PRODUCER_SUCCESS':
      return {
        ...state,
        loading: false,
        payload: action.payload
      }

    case 'LIST_PRODUCER_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default: return state
  }
}

export default listProducerReducer