
export default function actionGetCart() {
  return async (dispatch) => {
    try {
      dispatch({        
        type: 'GET_CART_BEGIN'
      })

      const result = await localStorage.getItem('nabawi-cart')

      dispatch({        
        type: 'GET_CART_SUCCESS',
        payload: (result)? JSON.parse(result) : []
      })
    } catch(err) {
      dispatch({        
        type: 'GET_CART_FAILURE',
        error: err
      })
    }
  }
}