const initialState = {
  loading: true,
  token: '',
  account: null,
  status: false
}

function authenticateReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_AUTHENTICATE_BEGIN':
      return {
        ...state,
        loading: true
      }

    case 'GET_AUTHENTICATE_SUCCESS':
      return {
        ...state,
        status: true,
        loading: false,
        token: action.token,
        account: action.account
      }
    
    case 'GET_AUTHENTICATE_FAILURE':
      return {
        ...state,
        status: false,
        loading: false,
        token: '',
        account: null
      }

    default: 
      return state
  }
}

export default authenticateReducer