
function requestAPI(name, total, token) {
  return fetch(`${process.env.REACT_APP_AGENT_URL}voucher/${name}?total=${total}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  }).then((res) => res.json())
}

export default (voucher, total) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: 'GET_VOUCHER_BEGIN' })

      if (!voucher) {
        dispatch({
          type: 'GET_VOUCHER_SUCCESS',
          payload: null
        })
        return
      }

      const token = await getState().auth.authenticate.token
      const result = await requestAPI(voucher, total, token)
      if(!result.status) {
        handleErrors(dispatch, result)
        return
      }

      dispatch({
        type: 'GET_VOUCHER_SUCCESS',
        payload: result.data
      })
    } catch (err) {
      handleErrors(dispatch, err)
    }
  }
}

function handleErrors(dispatch, error) {
  dispatch({        
    type: 'GET_VOUCHER_FAILURE',        
    error: error    
  })
}