import { actionGetCart } from '../cart'

export default function actionLogout() {
  return async (dispatch) => {
    try {
      await localStorage.clear()
      dispatch(actionGetCart())
      dispatch({        
        type: 'GET_AUTHENTICATE_FAILURE'
      })
    } catch(err) {
      dispatch({        
        type: 'GET_AUTHENTICATE_FAILURE',
        error: err
      })
    }
  }
}