import React, { useState } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import PropTypes from 'prop-types'

const CustomCarousel = ({ banners }) => {
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  return (
    <Carousel 
      activeIndex={index} 
      onSelect={handleSelect}
      id="carouselExampleIndicators"
    >
      {
        banners.map((banner, index) => {
          return (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={process.env.REACT_APP_IMAGE_URL + banner.image}
                alt="Gambar Banner"
              />
            </Carousel.Item>
          )
        })
      }
    </Carousel>
  )
}

CustomCarousel.propTypes = {
  banners: PropTypes.array.isRequired
}

export default CustomCarousel
