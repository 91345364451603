const initialState = { 
  loading: false,
  payload: [],
  payloadMobile: [],
  payloadAll: [],
  error: null
}

function listCategoryReducer(state = initialState, action) {
  switch (action.type) {
    case 'LIST_CATEGORY_BEGIN':
      return {
        ...state,
        loading: true,
        error: null
      }

    case 'LIST_CATEGORY_SUCCESS':
      return {
        ...state,
        loading: false,
        payload: action.payload,
        payloadMobile: action.payloadMobile,
        payloadAll: action.payloadAll
      }

    case 'LIST_CATEGORY_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default: return state
  }
}

export default listCategoryReducer