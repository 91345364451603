const initialState = {
  loading: false,
  success: null,
  error: null
}

function loginReducer(state = initialState, action) {
  switch (action.type) {
    case 'AUTH_LOGIN_BEGIN':
      return {
        ...state,
        loading: true,
        success: null,
        error: null
      }

    case 'AUTH_LOGIN_SUCCESS':
      return {
        ...state,
        success: action.success,
        loading: false
      }

    case 'AUTH_LOGIN_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default: 
      return state
  }
}

export default loginReducer