const initialState = {
  status: false,
  severity: 'error',
  message: ''
}

function alertReducer(state = initialState, action) {
  switch (action.type) {
    case 'UTIL_ALERT':
      return {
        ...state,
        status: action.status,
        severity: action.severity,
        message: action.message
      }

    default: 
      return state
  }
}

export default alertReducer