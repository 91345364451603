
export default function actionRemoveCart(productId) {
  return async(dispatch) => {
    try {
      dispatch({
        type: 'REMOVE_CART_BEGIN'
      })
      
      const result = await localStorage.getItem('nabawi-cart')
      const payload = (result)? JSON.parse(result) : []
      const indexExists = await payload.findIndex((item) => item.product.id === productId)

      if(indexExists > -1) {
        await payload.splice(indexExists, 1)
      } 
      
      localStorage.setItem('nabawi-cart', JSON.stringify(payload))

      dispatch({
        type: 'REMOVE_CART_SUCCESS',
        payload: payload
      })
    } catch (err) {
      dispatch({
        type: 'REMOVE_CART_FAILURE'
      })
    }
  }
}