
export default (number, precentage, prefix) => {
  const discount = number * (precentage/100)
  const currency = (number - discount).toFixed(2).split('.')

  if(number < 1000) {
    return (prefix)
    ? prefix + currency[0]
    : currency[0]
  }

  return (prefix)
    ? prefix + (currency[0]/1000).toFixed(3)
    : (currency[0]/1000).toFixed(3)
}