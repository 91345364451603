
export default function actionAddCart(product, price, quantity) {
  return async(dispatch) => {
    try {
      dispatch({
        type: 'ADD_CART_BEGIN'
      })
      
      const response = { message: '' }
      const result = await localStorage.getItem('nabawi-cart')
      const payload = (result)? JSON.parse(result) : []
      const indexExists = await payload.findIndex((item) => item.product.id === product.id)

      if(indexExists > -1) {
        payload[indexExists].quantity = payload[indexExists].quantity + quantity
        payload[indexExists].price = price
        response.message = 'Produk sudah ditambahkan ke keranjang.'
      } else {
        payload.push({ product: product, price: price, quantity: quantity })
        response.message = 'Berhasil menambahkan ke keranjang.'
      }
      
      localStorage.setItem('nabawi-cart', JSON.stringify(payload))

      dispatch({
        type: 'ADD_CART_SUCCESS',
        success: response,
        payload: payload
      })
    } catch (err) {
      dispatch({
        type: 'ADD_CART_FAILURE'
      })
    }
  }
}