
function requestAPI(token) {
  return fetch(`${process.env.REACT_APP_AGENT_URL}profile`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  }).then((res) => res.json())
}

export default function actionGetAccount(token) {
  return async (dispatch) => {
    dispatch({
      type: 'GET_ACCOUNT_BEGIN'
    })

    try {
      const result = await requestAPI(token)
      if(!result.status) {
        handleErrors(dispatch, result)
        return
      }

      await storeAccountData(result.data)

      dispatch({
        type: 'GET_ACCOUNT_SUCCESS'
      })
    } catch (err) {
      handleErrors(dispatch, err)
    }
  }
}

function handleErrors(dispatch, error) {
  dispatch({        
    type: 'GET_ACCOUNT_FAILURE',        
    error: error    
  })
}

function storeAccountData(account) {
  return localStorage.setItem('nabawi-account', JSON.stringify(account))
}