const initialState = {
  active: false
}

function modalReducer(state = initialState, action) {
  switch (action.type) {
    case 'UTIL_MODAL':
      return {
        ...state,
        active: action.active
      }

    default: 
      return state
  }
}

export default modalReducer