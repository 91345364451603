import { encodeParams } from '../../../helpers'

function requestAPI(queryString) {
  return fetch(`${process.env.REACT_APP_BASE_URL}product?${queryString}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json())
}

export default function actionListProduct(page, limit, name, producerId, categoryId) {
  return async (dispatch) => {
    dispatch({
      type: 'LIST_PRODUCT_BEGIN'
    })

    try {
      const queryString = await encodeParams({ 
        page: page, 
        limit: limit, 
        name: name, 
        producer_id: producerId,
        category_id: categoryId
      })
      
      const result = await requestAPI(queryString)
      if(!result.status) {
        handleErrors(dispatch, result)
        return
      }

      dispatch({
        type: 'LIST_PRODUCT_SUCCESS',
        page: result.data.page,
        pages: result.data.pages,
        payload: result.data.data
      })
    } catch (err) {
      handleErrors(dispatch, err)
    }
  }
}

function handleErrors(dispatch, error) {
  dispatch({        
    type: 'LIST_PRODUCT_FAILURE',        
    error: error    
  })
}