
export default function actionRemoveAllCart() {
  return async(dispatch) => {
    try {
      dispatch({
        type: 'REMOVE_ALL_CART_BEGIN'
      })
      
      localStorage.removeItem('nabawi-cart')

      dispatch({
        type: 'REMOVE_ALL_CART_SUCCESS',
        payload: []
      })
    } catch (err) {
      dispatch({
        type: 'REMOVE_ALL_CART_FAILURE'
      })
    }
  }
}