import { combineReducers } from 'redux'

import alert from './alert'
import modal from './modal'
import contact from './contact'

export default combineReducers({
  alert: alert,
  modal: modal,
  contact: contact
})
