import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Form } from './components'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionChangePassword } from '../../store/actions/auth'

const ChangePassword = ({ 
  history,
  changePassword, 
  dispatchChangePassword
}) => {
  const [onState, setOnState] = useState(false)
  // const [modalSuccess, setModalSuccess] = useState(false)

  useEffect(() => {
    if(changePassword.success && onState) {
      setOnState(false)
      history.push('/')
    }
  }, [changePassword.success, onState])

  const handleChangePassword = (data) => {
    // if(!changePassword.loading) {
      dispatchChangePassword(data)
      setOnState(true)
    // }
  }

  return (
    <div className="container">
      <Form 
          loading={changePassword.loading}
          onChangePassword={handleChangePassword}
        />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    changePassword: {
      loading: state.auth.changePassword.loading,
      success: state.auth.changePassword.success
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchChangePassword: (data) => dispatch(actionChangePassword(data))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ChangePassword)