
export default function actionGetAuthenticate() {
  return async (dispatch) => {
    try {
      dispatch({        
        type: 'GET_AUTHENTICATE_BEGIN'
      })
      
      const [token, account] = await Promise.all([
        localStorage.getItem('nabawi-token'),
        localStorage.getItem('nabawi-account')
      ])

      if(!token || !account) {
        dispatch({        
          type: 'GET_AUTHENTICATE_FAILURE'
        })
        return
      }

      dispatch({        
        type: 'GET_AUTHENTICATE_SUCCESS',
        token: token,
        account: JSON.parse(account)
      })
    } catch(err) {
      dispatch({        
        type: 'GET_AUTHENTICATE_FAILURE',
        error: err
      })
    }
  }
}