
function requestAPI() {
  return fetch(`${process.env.REACT_APP_BASE_URL}contact-dropshipping`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json())
}

export default function actionContact() {
  return async (dispatch) => {
    dispatch({
      type: 'GET_CONTACT_BEGIN'
    })

    try {
      const result = await requestAPI()
      if(!result.status) {
        handleErrors(dispatch, result)
        return
      }

      dispatch({
        type: 'GET_CONTACT_SUCCESS',
        payload: {
          phone: result.data.phone.replace(0, '62')
        }
      })
    } catch (err) {
      handleErrors(dispatch, err)
    }
  }
}

function handleErrors(dispatch, error) {
  dispatch({        
    type: 'GET_CONTACT_FAILURE',        
    error: error    
  })
}